.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.add-to-home-banner {
  bottom: 1px !important;
}
.add-to-home-banner .add-to-home-icon {
  padding: 0px !important;
}
.add-to-home-banner {
  z-index: 9999;
  background: #0d1821 !important;
  color: #89c0e3 !important;
  border: 1px solid #008ce96b !important;
}

.Mui-disabled {
  background: rgba(255, 255, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.3) !important;
  box-shadow: none !important;
}

button .MuiButton-label {
  font-weight: bold;
}

.awssld {
  /*min-height: 250px;*/
  max-width: 350px !important;
  --slider-height-percentage: 45% !important;
  --slider-transition-duration: 525ms !important;
  --organic-arrow-thickness: 1px !important;
  --organic-arrow-border-radius: 0px !important;
  --organic-arrow-height: 10px !important;
  --organic-arrow-color: transparent !important;
  --control-button-width: 10%!important;
  --control-button-height: 25% !important;
  --control-button-background: transparent !important;
  --control-bullet-color: transparent !important;
  --control-bullet-active-color: transparent !important;
  --loader-bar-color:transparent!important;
  --loader-bar-height: 6px !important;
  --content-background-color: transparent !important;
}

.awssld__content > img, .awssld__content > video {
  object-fit: contain !important;
}

.awssld__content {
  padding-bottom: 0px !important;
}

.awssld .awssld__timer {
  opacity: 0;
  pointer-events: none; /* prevent events (mouse clicking etc.) */
}